<template>
    <!-- 导航 -->
    <page-head title="训练营" />
    <content-bg>
        <template v-slot:content>
            <div class="menuList" v-if="menuList && menuList.length">
                <div
                     :key="item.id"
                     v-for="(item,index) in menuList"
                     class="menuItem" @click="menuClick(item)">
                    <index-number>
                        <template v-slot:number>{{ index + 1 }}</template>
                    </index-number>
                    <menu-card>
                        <template v-slot:icon><img class="icon" :src="item.icon" alt=""></template>
                        <template v-slot:label>
                            <p class="label YouSheBiaoTiHei"
                               :class="item.name.length>6 ? 'small' : ''">{{ item.name }}</p>
                        </template>
                    </menu-card>
                </div>
            </div>
            <no-data v-else></no-data>
        </template>
    </content-bg>
    <pagination-com :current-page="query.page" :total-page="totalPage"
                    @changePage="changePage"></pagination-com>
</template>

<script>
import {useRouter} from "vue-router";
import {getCurrentInstance, reactive, toRefs, onMounted, watch} from "vue";
import {useStore} from "vuex";

export default {
    name: "camp",
    setup() {

        const router = useRouter()
        const {proxy} = getCurrentInstance()
        const store = useStore()

        const state = reactive({
            menuList: [],

            // 显示的菜单，分页
            query: {
                paging: true,
                pageSize: 6,
                page: 1,
            },
            totalPage: ''
        });


        const getCampList = async () => {
            const {
                code,
                data: { list, pages }
            } = await proxy.$server.getCampList(state.query);
            if (code === 200) {
                state.menuList = [];

                list.map(item => {
                    state.menuList.push({
                        ...item,
                        icon: require('@i/firstMenu/xunlianying.svg'),
                        router: {
                            name: 'mainMenu',
                            path: '/menu/index',
                            query: {
                                type: 'camp', // team/camp 普通梯队或者训练营
                            }
                        }
                    })
                })

                state.totalPage = pages;
            }
        }

        watch(() => store.state.identifyData, (val, old) => {
            console.log(val, old);
            if (!val.type) return false;
            const params = {
                page: state.query.page,
                totalPage: state.totalPage,
                showList: state.menuList,
                ...val,
            }
            proxy.$utils.identifyFn({
                type: val.type,
                data: params,
                changePage: changePage,
                router: menuClick
            })
        })

        onMounted(() => {
            getCampList();
        })

        const changePage = (page) => {
            state.query.page = page
            getCampList()
        }

        const menuClick = (item) => {
            store.commit('setTeamData', item)
            router.push({
                ...item.router
            })
        }


        return {
            ...toRefs(state),
            changePage,
            menuClick
        }
    }
}
</script>

<style scoped lang="scss">

::v-deep .menuItem {
    width: 360px;
    height: 320px;
    margin-right: 91px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 40px;
    text-align: center;

    &:nth-child(3n+1) {
        margin-left: 55px;
    }
    &:nth-child(3n) {
        margin-right: 0;
    }

    .cardItem {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .icon {
        width: 150px;
        height: 150px;
        margin-top: 43px;
        margin-bottom: 30px;
    }

    .label {
        font-weight: 400;
        font-size: 36px;
        line-height: 50px;
        padding-left: 20px;
        padding-right: 20px;

        &.small {
            font-size: 32px;
            line-height: 40px;
        }
    }
}

::v-deep.indexNum {
    left: 16px;
    top: 16px;
}

::v-deep.pagination {
    position: absolute;
    left: 592px;
    top: 978px;
}
.menuList {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 54px;
}
</style>